<template>
  <span>
    <span v-if="status" :style="[status === 'Online' ? {'color': '#38AD57'} : {'color': 'red'}]">
      {{status}}
    </span>
    <span v-if="!status">All</span>
    <span :style="[status ? status === 'Online' ? {'color': '#38AD57'} : {'color': 'red'} : {'color': 'black'}]">
      ({{ total || 0 }}) <span v-if="status" style="color: gray">|<a id="show-all" @click="showAll"> Show All</a></span>
    </span>
  </span>
</template>

<script>

  export default {
    props: ['total','status', 'showAll'],
  }
</script>

<style>
  #show-all:hover{
    text-decoration: underline;
  }
</style>

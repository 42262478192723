<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">Hotspot Machines</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        solo
        hide-details
        single-line
        placeholder="Search"
        v-model="q"
        @keydown="searchInput"
      ></v-text-field>
      <v-dialog
        v-model="dialog"
        max-width="300px"
      >
        <template v-slot:activator="{on, attrs}">
          <v-btn
            icon
            class="btn-no-active"
            title="Status Filter"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-filter-menu-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Filter Status</v-card-title>
          <v-divider></v-divider>
          <v-container fluid>
            <v-row no-gutters>
              <v-select 
                :items="items"
                filled
                item-value="_d"
                v-model="status"
                requred>
              </v-select>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" @click="done">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </template>
    <template slot="content">

      <loading-circular v-if="loading && !allMachines.length"></loading-circular>
      <v-alert rounded="0" type="error" v-if="error">{{ error }}</v-alert>
      <v-alert type="info" v-if="!loading && !allMachines.length && !q" rounded="0">No data to show.</v-alert>
      <v-alert type="info" v-if="!loading && !allMachines.length && q" rounded="0">No results for search term "{{q}}"</v-alert>
      <v-alert type="success" v-if="!error && allMachines.length && q && entered" rounded="0">Showing search results for "{{q}}"</v-alert>

      <h3 class="mt-4 mx-4">
        <machines-heading-text :total="total_items" :status="filteredStatus" :show-all="showAll"/>
      </h3>
      <template v-if="!error && allMachines.length">
        <v-list
          three-line
          nav
          v-if="allMachines.length"
          class="mx-4"
        >

          <template v-for="(m, i) in allMachines">

            <v-list-item :key="i" :value="m" :to="'machines/' + m.id">

              <rand-list-item-avatar
                :str="m.cid"
                size="56"
              >
              </rand-list-item-avatar>
              <v-list-item-content :class="{'success--text': m.online}">
                <v-list-item-title>
                  {{ m.alias || m.cid }}
                  <span v-if="m.online">
                    (online)
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="m.product_key">License: {{ m.product_key.code }}</v-list-item-subtitle>
                <v-list-item-subtitle>Software: {{ m.software_version }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </template>

        </v-list>

        <div v-observe-visibility="reachedBottom"></div>

        <template
          v-if="page < total_pages || loading"
          class="text-center my-6"
        >
          <div class="text-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </template>

      </template>


    </template>

  </dashboard-layout>
</template>

<script>
  import MachinesHeadingText from '@/views/dashboard/machines/MachinesHeadingText'
  import { mapGetters, mapActions } from 'vuex'

  let prev_q;

  export default {
    name: 'Machines',
    components: {
      MachinesHeadingText
    },
    data() {
      return {
        loading: true,
        error: null,
        selected: null,
        entered: false,
        q: '',
        page: 1,
        per_page: 15,
        total_items: 0,
        total_pages: 0,
        items: ['Online', 'Offline'],
        status: '',
        dialog: false,
        filteredStatus: '',
      }
    },
    created() {
      this.reset()
      this.load()
    },
    methods: {
      reset() {
        this.clearMachines()
        this.q = ''
        this.page = 1
        this.entered = false
      },
      done(){
        this.filteredStatus = this.status
        this.reset()
        this.load()
      },
      showAll(){
        this.q = ''
        this.status = null
        this.filteredStatus = status
        this.reset()
        this.load()
      },
      async load() {
        const is_online = this.status ? this.status === 'Online' ? true : false : null
        this.dialog = 0;
        try {
          this.error = null
          this.loading = true
          const { total_items, total_pages } = await this.fetchMachines({
            page: this.page, per_page: this.per_page, q: this.q, is_online
          })
          this.total_items = total_items
          this.total_pages = total_pages

        } catch(e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      searchInput(e) {
        if (this.q && (e.key === 'Enter' || e.keyCode === 13)) {
          this.entered = true
          this.clearMachines()
          this.page=1
          this.load()
        } else if (!this.q && prev_q) {
          this.reset()
          this.load()
        } else if (this.q && (e.key === "Escape" || e.keyCode === 27)) {
          this.reset()
          this.load()
        }
        prev_q = this.q
      },
      reachedBottom() {
        if (!this.loading && this.allMachines.length && this.page < this.total_pages) {
          this.page ++
          this.load()
        }
      },
      ...mapActions(['fetchMachines', 'clearMachines'])
    },
    computed: {
      ...mapGetters(['allMachines'])
    },
    watch: {
      selected(machine) {
        this.$router.push({name: 'Machine', params: {id: machine.id}})
      }
    }
  }
</script>
